import { Statistic } from 'antd'
import { t } from 'src/locale'
import styled from 'styled-components'
import { SingleEventHeaderPrice } from '../../SingleEvent/SingleEventHeader'

export const EventComponentContainer = styled.a`
  background: ${({ theme }) => theme.colors.background.secondary};
  color: ${({ theme }) => theme.colors.fontColor.primary};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 10px;
  min-height: 120px;
  gap: 4px;
  cursor: pointer;
  flex-flow: column nowrap;
  display: flex;
  place-items: flex-start;
  position: relative;
  padding-bottom: 35px;
  @media screen and (min-width: 550px) {
    flex-flow: row nowrap;
    gap: 10px;
    padding-bottom: 10px;
  }
`

export const EventComponentImg = styled.img`
  width: 100%;
  height: auto;

  @media screen and (min-width: 550px) {
    width: auto;
    height: 100%;
    max-height: 120px;
    max-width: 213.33px;
  }
`
export const EventComponentImage = styled.div<{
  isToday?: boolean
  isInProgress?: boolean
  archived?: boolean
}>`
  height: fit-content;
  width: 100%;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.border.radius};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-bottom: 1px solid
    ${({ isToday, isInProgress, archived, theme }) => {
      if (archived) {
        return theme.colors.disabled.background
      } else if (isInProgress) {
        return theme.colors.green.main
      } else if (isToday) {
        return theme.colors.purple.main
      } else {
        return theme.colors.borderColor
      }
    }};
  position: relative;
  @media screen and (min-width: 550px) {
    width: 100%;
    height: 100%;
    max-height: 120px;
    max-width: 213.33px;
  }
  &::before {
    place-items: center;
    ${({ isToday, isInProgress, archived, theme }) => {
      if (archived) {
        return `
              color: ${theme.colors.disabled.color};
              background: ${theme.colors.disabled.background};
              content: '${t('EventEnded')}';
              display: flex;
              `
      } else if (isInProgress) {
        return `
              color: ${theme.colors.black};
              background: ${theme.colors.green.main};
              content: '${t('EventInProgess')}';
              display: flex;
              `
      } else if (isToday) {
        return `
              color: ${theme.colors.white};
              background: ${theme.colors.purple.main};
              content: '${t('EventToday')}';
              display: flex;
              `
      } else {
        return `
          display: none;
          `
      }
    }}
    position: absolute;
    bottom: 0px;
    left: 5px;
    padding: 0px 10px;
    padding-bottom: 0px;
    border-top-right-radius: ${({ theme }) => theme.border.radius};
    border-top-left-radius: ${({ theme }) => theme.border.radius};

    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSize.textS};
  }
`
export const EventComponentDetails = styled.div`
  display: contents;
  @media screen and (min-width: 550px) {
    display: flex;
    flex-flow: column nowrap;
  }
`
export const EventComponentTitle = styled.div`
  padding: 0;
  h2 {
    font-size: ${({ theme }) => theme.fontSize.heading5};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  width: 100%;
  display: block;
`

export const EventComponentMode = styled.div`
  display: flex;
  place-items: center;
  gap: 5px;
`

export const BottomRight = styled.div`
  position: absolute;
  bottom: -1px;
  right: -1px;
  border-top-left-radius: 10px;
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-left: 1px solid ${({ theme }) => theme.colors.borderColor};
  background: ${({ theme }) => theme.colors.background.tertiary};
  padding-left: 10px;
`

export const EventComponentPrice = styled(Statistic)<{ $free?: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.textM};
  text-transform: uppercase;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  gap: 3px;
  padding-left: 5px;
  .ant-statistic-content {
    border-top: 1px solid
      ${({ theme, $free }) =>
        $free ? theme.colors.green.main : theme.colors.purple.main} !important;
    padding: 0px 7px;
    display: flex;
    place-items: center;
    background: ${({ theme, $free }) =>
      $free ? theme.colors.green.main : theme.colors.purple.main} !important;
    color: ${({ theme, $free }) =>
      $free ? theme.colors.black : theme.colors.white} !important;
    margin-top: -1px;
    border-top-left-radius: 10px;
  }
  .ant-statistic-content-value {
    color: ${({ theme, $free }) =>
      $free ? theme.colors.black : theme.colors.white} !important;
    font-family: ${({ theme }) => theme.fontFamily.primary} !important;
    font-size: ${({ theme }) => theme.fontSize.textL};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
  }
  .ant-statistic-content-suffix,
  .ant-statistic-content-value-decimal {
    color: ${({ theme, $free }) =>
      $free ? theme.colors.black : theme.colors.white} !important;
    font-family: ${({ theme }) => theme.fontFamily.primary} !important;
    font-size: ${({ theme }) => theme.fontSize.textXM};
  }
`

export const EventData = styled.div`
  display: flex;
  place-items: flex-start;
  flex-direction: column;
  gap: 5px;
`

export const EventCategoriesWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  place-items: center;
  gap: 5px;
`
