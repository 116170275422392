import { combineReducers } from 'redux'
import {
  accountLoginSlice,
  accountProfileSlice,
  accountRegisterSlice,
  activeAccountSlice,
  fetchedAccountSlice,
} from './Account'
import { fetchedAccountsSlice } from './Account/fetchedAccountsSlice'
import { fetchedAccountsSliceFULL } from './Account/fetchedAccountsSliceFULL'
import { patronsSlice } from './Account/patronsSlice'
import { categoriesSlice } from './Categories'
import { accountEventsSlice, singleEventSlice, eventsListSlice } from './Events'
import { tagsSlice } from './Tags'
import { eventsFeedSlice, eventsFeedQuerySlice } from './EventsFeed'

export const rootReducer = combineReducers({
  account: combineReducers({
    login: accountLoginSlice.reducer,
    register: accountRegisterSlice.reducer,
    fetchedAccount: fetchedAccountSlice.reducer,
    activeAccount: activeAccountSlice.reducer,
    accountProfile: accountProfileSlice.reducer,
    fetchedAccounts: fetchedAccountsSlice.reducer,
    fetchedAccountsFULL: fetchedAccountsSliceFULL.reducer,
    patrons: patronsSlice.reducer,
  }),
  events: combineReducers({
    single: singleEventSlice.reducer,
    accountEvents: accountEventsSlice.reducer,
    eventsList: eventsListSlice.reducer,
    feed: eventsFeedSlice.reducer,
    feedQuery: eventsFeedQuerySlice.reducer,
  }),
  categories: combineReducers({
    all: categoriesSlice.reducer,
  }),
  tags: combineReducers({
    all: tagsSlice.reducer,
  }),
})
