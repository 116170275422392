import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'src/types'

type EventsFeedQuerySliceDataType = {
  query: {
    tags?: string[]
    free?: boolean
    finished?: boolean
    search?: string
  }
}

const initialState: EventsFeedQuerySliceDataType = {
  query: {
    tags: undefined,
    free: undefined,
    finished: true,
    search: undefined,
  },
}

export const eventsFeedQuerySlice = createSlice({
  name: 'eventsFeedQuerySlice',
  initialState,
  reducers: {
    setTagsQuery: (state, action: PayloadAction<string[] | undefined>) => {
      state.query.tags = action.payload
    },
    setFreeQuery: (state, action: PayloadAction<boolean | undefined>) => {
      state.query.free = action.payload
    },
    setFinishedQuery: (state, action: PayloadAction<boolean | undefined>) => {
      state.query.finished = action.payload
    },
    setSearchQuery: (state, action: PayloadAction<string | undefined>) => {
      state.query.search = action.payload
    },
  },
})

export const eventsFeedQuerySelector = createSelector(
  (state: RootState) => state.events.feedQuery.query,
  (query) => query
)

export const { setTagsQuery, setFreeQuery, setFinishedQuery, setSearchQuery } =
  eventsFeedQuerySlice.actions
