import styled from 'styled-components'

export const ListContainer = styled.div`
  padding: 20px;
  gap: 20px;
  display: flex;
  flex-flow: column nowrap;
  max-width: 800px;
  width: 100%;
  padding-top: 10px;
`

export const ListBox = styled.div`
  width: 100%;
  place-items: center;
  display: flex;
  flex-flow: column nowrap;
`
