import { Profile, Accounts, Settings, AdminPanel, EventsPage } from '../pages'
import { RoutesConfig } from '../types'

export const routesConfig: RoutesConfig = {
  profile: {
    PageComponent: Profile,
    path: '/@:username',
  },
  home: {
    PageComponent: EventsPage,
    path: '/',
  },
  categories: {
    PageComponent: EventsPage,
    path: '/:categoryKey',
  },
  explore: {
    PageComponent: Accounts,
    path: '/accounts',
  },
  settings: {
    PageComponent: Settings,
    path: '/settings',
  },
  adminPanel: {
    PageComponent: AdminPanel,
    path: '/adminPanel',
  },
}
