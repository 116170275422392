import { ListBox, ListContainer } from './styles'
import { useEventsFeed } from 'src/hooks/EventsFeed/useEventsFeed'
import { EventFeedComponent } from 'src/components'
import { AppLoading } from 'src/components/AppComponents/AppLoading'

export const List = () => {
  const {
    events,
    get: { isLoading },
  } = useEventsFeed()

  const renderList = events.map((event, index) => (
    <EventFeedComponent key={index} event={event} />
  ))

  if (isLoading)
    return (
      <ListBox>
        <AppLoading />
      </ListBox>
    )

  return (
    <ListBox>
      <ListContainer>{renderList}</ListContainer>
    </ListBox>
  )
}
