import { ReactElement } from 'react'
import { MdLaptop, MdLocationOn } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import {
  SingleEventHeaderDate,
  SingleEventHeaderProfile,
} from 'src/components/Events/SingleEvent/SingleEventHeader'
import { t } from 'src/locale'
import { Event, EventFeed } from 'src/types'
import { getEventStatus } from 'src/utils'
import {
  BottomRight,
  EventComponentContainer,
  EventComponentDetails,
  EventComponentImage,
  EventComponentImg,
  EventComponentMode,
  EventComponentPrice,
  EventComponentTitle,
  EventData,
} from './EventFeedComponent.styles'
import { EventCategories } from '../Events/EventComponent/EventCategories'

type EventFeedComponentProps = {
  event: EventFeed
}

type PrivacyListType = {
  [key: string]: {
    icon: ReactElement
    content: string
  }
}

export const EventFeedComponent = ({ event }: EventFeedComponentProps) => {
  const navigate = useNavigate()

  const { from, to } = event

  const { eventId, tags, archived, title, image, author, free, price } =
    event.parent

  const onClick = () => {
    navigate(`/events/${eventId}`)
  }

  const mode: PrivacyListType = {
    stationary: {
      icon: <MdLocationOn size={23} />,
      content: t('Stationary'),
    },
    hybrid: {
      icon: (
        <>
          <MdLocationOn size={23} />
          <MdLaptop size={23} />
        </>
      ),
      content: t('Hybrid'),
    },
    remote: {
      icon: <MdLaptop size={23} />,
      content: t('Remote'),
    },
  }

  const modeTag = tags.filter((tag) => tag.type === 'mode')[0]

  const { isToday, isInProgress } = getEventStatus({ from, to })

  return (
    <EventComponentContainer to={`/events/${eventId}`}>
      <EventComponentImage
        isInProgress={isInProgress}
        isToday={isToday}
        archived={archived}
      >
        <EventComponentImg
          alt={title}
          src={
            image
              ? image?.light_url
              : `/${
                  localStorage.getItem('theme') === 'light' ? 'light' : 'dark'
                }-169placeholder.webp`
          }
        />
      </EventComponentImage>
      <EventComponentDetails>
        {from && to ? (
          <SingleEventHeaderDate from={from} to={to} />
        ) : (
          t('Draft')
        )}
        <EventComponentTitle>
          <h2>{title}</h2>
        </EventComponentTitle>
        <EventData>
          <SingleEventHeaderProfile
            username={author.username}
            avatar={author.avatar}
            isUserVerified={author.isUserVerified}
            roles={author.roles}
            name={author.name}
          />
          <EventCategories tags={tags} />
        </EventData>
        <BottomRight>
          <EventComponentMode>
            {modeTag && mode[modeTag.key].icon}
            <EventComponentPrice
              $free={free}
              value={free ? t('Free') : price}
              precision={2}
              suffix={free ? '' : 'zł'}
            />
          </EventComponentMode>
        </BottomRight>
      </EventComponentDetails>
    </EventComponentContainer>
  )
}
