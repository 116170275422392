import {
  eventsFeedQuerySelector,
  eventsFeedSelector,
  eventsFeedStateSelector,
  getEventsFeedAction,
} from 'src/store'
import { useAppSelector } from '../useAppSelector'
import { useAppDispatch } from '../useAppDispatch'
import { useEffect } from 'react'
import { formatQueryTyped } from 'src/utils'

export const useEventsFeed = () => {
  const dispatch = useAppDispatch()
  const query = useAppSelector(eventsFeedQuerySelector)
  const events = useAppSelector(eventsFeedSelector.selectAll)
  const { count, tags, get } = useAppSelector(eventsFeedStateSelector)

  useEffect(() => {
    dispatch(
      getEventsFeedAction({
        query: formatQueryTyped(query),
      })
    )
  }, [query])

  return {
    events,
    count,
    tags,
    get,
  }
}
