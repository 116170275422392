import {
  PayloadAction,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { EventFeed, EventsFeed, RootState, SliceStatus } from 'src/types'
import { getEventsFeedAction } from './actions'

type EventsFeedSliceData = {
  tags: string[]
  count: number
  get: SliceStatus
}

export const eventsFeedAdapter = createEntityAdapter<EventFeed>({
  selectId: ({ _id }) => _id,
})

export const eventsFeedSlice = createSlice({
  name: 'eventsFeedSlice',
  initialState: eventsFeedAdapter.getInitialState<EventsFeedSliceData>({
    tags: [],
    count: 0,
    get: {
      isLoading: false,
      error: null,
    },
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEventsFeedAction.pending, (state) => {
      state.get.isLoading = true
    })
    builder.addCase(
      getEventsFeedAction.fulfilled,
      (state, action: PayloadAction<EventsFeed>) => {
        eventsFeedAdapter.setAll(state, action.payload.data)
        state.get.isLoading = false
        state.get.error = null
        state.count = action.payload.count
        state.tags = action.payload.tags
      }
    )
    builder.addCase(getEventsFeedAction.rejected, (state, action) => {
      state.get.isLoading = false
      state.get.error = action.payload
      state.count = 0
      state.tags = []
    })
  },
})

export const eventsFeedSelector = eventsFeedAdapter.getSelectors<RootState>(
  (state) => state.events.feed
)

export const eventsFeedStateSelector = createSelector(
  (state: RootState) => state.events.feed.count,
  (state: RootState) => state.events.feed.tags,
  (state: RootState) => state.events.feed.get,
  (count, tags, get) => ({
    count,
    tags,
    get,
  })
)
